import cachios from 'cachios';

export function updateSiteHeaderBasketNumber(number = null) {
    const $basketNumber = document.querySelector('.site-header-basket__number');
    const $modalMenuBasketNumber = document.querySelector('.site-menu-modal__basket-number');

    if ($basketNumber) {
        $basketNumber.innerHTML = number;
    }

    if ($modalMenuBasketNumber) {
        $modalMenuBasketNumber.innerHTML = number;

        if (number == 0) {
            $modalMenuBasketNumber.classList.remove('site-menu-modal__basket-number--show');
        } else {
            $modalMenuBasketNumber.classList.add('site-menu-modal__basket-number--show');
        }
    }
}

(() => {
    const $siteHeader = document.querySelector('.site-header');
    const $locationForm = document.querySelector('.site-header-location');
    const $locationSelect = document.querySelector('.site-header-location__select');

    const setBasketNumber = () => {
        cachios.get(GLOBALS.AJAX_URL, {
            params: {
                action: 'armourcoat_theme_ajax_get_cart_number',
            }
        }).then(response => {
            updateSiteHeaderBasketNumber(response?.data?.cartItemCount);
        });
    };

    const initLocationForm = () => {
        if (!$locationForm || !$locationSelect) return;

        $locationSelect.addEventListener('change', () => {
            $locationForm.submit();
        });
    };

    const siteHeaderBorder = () => {
        if (!$siteHeader) return;

        if (window.scrollY === 0) {
            $siteHeader.classList.remove('site-header--show-border');
        } else {
            $siteHeader.classList.add('site-header--show-border');
        }
    };

    window.addEventListener('scroll', () => {
        siteHeaderBorder();
    });

    setBasketNumber();
    initLocationForm();
    siteHeaderBorder();
})();
