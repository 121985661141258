import Pristine from 'pristinejs';

(() => {
    const $validateForms = document.querySelectorAll('.validate-form');
    const $inputs = document.querySelectorAll('.input');
    const $multiFileInputs = document.querySelectorAll('.multi-file-input');

    const initTogglePasswordVisibility = () => {
        if (!$inputs) return;

        $inputs.forEach($inputWrap => {
            const $input = $inputWrap.querySelector('.input__input input, .input__input textarea, .input__input select');
            const $togglePasswordVisibility = $inputWrap.querySelector('.input__toggle-password-visibility');

            if (!$togglePasswordVisibility) return;

            $togglePasswordVisibility.addEventListener('click', () => {
                if ($input?.type === 'password') {
                    $input.type = 'text';
                    $togglePasswordVisibility.classList.add('input__toggle-password-visibility--visible');
                } else {
                    $input.type = 'password';
                    $togglePasswordVisibility.classList.remove('input__toggle-password-visibility--visible');
                }
            });
        });
    };

    const initFormValidation = () => {
        if (!$validateForms) return;

        $validateForms.forEach($validateForm => {
            const $formFileInputs = document.querySelectorAll('.input input[type=file]');

            const pristine = new Pristine($validateForm);
        
            $validateForm.addEventListener('submit', e => {
                if (!pristine.validate()) {
                    e.preventDefault();
                }
            });

            if ($formFileInputs) {
                $formFileInputs.forEach($input => {
                    const { pristineMaxFileSizeMessage } = $input.dataset;

                    pristine.addValidator($input, function(value) {
                        const maxFileSize = $input?.dataset?.maxFileSize ? parseInt($input.dataset.maxFileSize) : null;

                        if (!maxFileSize) return true;

                        let valid = true;

                        Array.from(this?.files)?.forEach(file => {
                            if (!valid) return;

                            valid = file.size <= maxFileSize;
                        });

                        return valid;
                    }, pristineMaxFileSizeMessage, 2, false);
                });
            }
        });
    };

    const initFileInputOverlayText = () => {
        if (!$inputs) return;

        $inputs.forEach($input => {
            const $fileInput = $input.querySelector('input[type=file]');
            const $inputOverlay = $input.querySelector('.input__overlay');

            if (!$fileInput || !$inputOverlay) return;

            $fileInput.addEventListener('change', () => {
                const files = $fileInput.files;

                if (!files) return;

                let overlayText = Array.from(files).map(file => file?.name);

                if (overlayText?.length) {
                    $inputOverlay.innerHTML = overlayText.join(', ');
                } else {
                    const { text } = $inputOverlay?.dataset;

                    $inputOverlay.innerHTML = text;
                }
            });
        });
    };

    const multiFileInputs = ($multiFileInputWrap, $multiFileInput) => {
        const $input = $multiFileInput.querySelector('input');
        const $remove = $multiFileInput.querySelector('.multi-file-input__remove');
        const $text = $multiFileInput.querySelector('.multi-file-input__input-text');

        if ($input) {
            //$input.value = '';
    
            $input.addEventListener('change', () => {
                const $newInput = $multiFileInput.cloneNode(true);

                if ($newInput) {
                    $multiFileInputWrap.insertAdjacentElement('afterbegin', $newInput);

                    multiFileInputs($multiFileInputWrap, $newInput);
                }

                $text.innerHTML = $input.files[0].name;

                $multiFileInput.classList.add('multi-file-input__input--close');
            });
        }

        if ($remove) {
            $remove.addEventListener('click', () => {
                $multiFileInput.remove();
            });
        }
    }

    const initMultiFileInputs = () => {
        if (!$multiFileInputs) return;

        $multiFileInputs.forEach($multiFileInput => {
            const $inputs = $multiFileInput.querySelectorAll('.multi-file-input__input');

            if (!$inputs) return;

            $inputs.forEach($input => {
                multiFileInputs($multiFileInput, $input);
            });
        });
    };

    initFormValidation();
    initFileInputOverlayText();
    initMultiFileInputs();
    initTogglePasswordVisibility();
})();
