import { enableBodyScroll, disableBodyScroll } from '../common/helpers';

const modalOpenEvent = new CustomEvent('modalopen', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const modalCloseEvent = new CustomEvent('modalclose', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const setModalWidths = () => {
    const $activeModals = document.querySelectorAll('.modal.modal--active, .modal-resize.modal--active');
    const $fixedEls = document.querySelectorAll('.fixed-el');

    let hasActiveModal = false;

    if ($activeModals.length) {
        $activeModals.forEach($modal => {
            if (!$modal.dataset?.modalMaxWidth || window.innerWidth < $modal.dataset.modalMaxWidth) {
                hasActiveModal = true;
            }
        });
    }

    if (!hasActiveModal) {
        enableBodyScroll();
        
        if ($fixedEls.length) {
            $fixedEls.forEach($fixedEl => {
                $fixedEl.style.width = '';
            });
        }

        if ($activeModals.length) {
            $activeModals.forEach($modal => {
                if ($modal.dataset?.modalMaxWidth && window.innerWidth >= $modal.dataset.modalMaxWidth) {
                    closeModal($modal);
                }
            });
        }

        return;
    } else {
        enableBodyScroll();

        if ($fixedEls.length) {
            $fixedEls.forEach($fixedEl => {
                $fixedEl.style.width = document.body.clientWidth + 'px';
            });
        }

        if ($activeModals.length) {
            $activeModals.forEach($modal => {
                if (!$modal.dataset?.modalMaxWidth || window.innerWidth < $modal.dataset.modalMaxWidth) {
                    $modal.style.width = document.body.clientWidth + 'px';
                }
            });
        }

        disableBodyScroll();
    }
};

export function closeModal($modal) {
    $modal.dispatchEvent(modalCloseEvent);

    const $modalToggleBtns = $modal.dataset?.modalToggleBtn ? document.querySelectorAll($modal.dataset.modalToggleBtn) : false;

    $modal.style.width = '';

    $modal.classList.remove('modal--active');

    if ($modalToggleBtns.length) {
        $modalToggleBtns.forEach($modalToggleBtn => {
            $modalToggleBtn.classList.remove('modal--active');
            $modalToggleBtn.ariaExpanded = false;
        });
    }

    setModalWidths();
}

export function openModal($modal) {
    $modal.dispatchEvent(modalOpenEvent);

    const $modalToggleBtns = $modal.dataset?.modalToggleBtn ? document.querySelectorAll($modal.dataset.modalToggleBtn) : false;

    $modal.style.width = $modal.offsetWidth + 'px';

    disableBodyScroll();

    $modal.classList.add('modal--active');

    if ($modalToggleBtns.length) {
        $modalToggleBtns.forEach($modalToggleBtn => {
            $modalToggleBtn.classList.add('modal--active');
            $modalToggleBtn.ariaExpanded = true;
        });
    }

    const escapeListener = e => {
        if (e.key !== 'Escape') return;

        closeModal($modal);

        document.removeEventListener('keyup', escapeListener);
    };

    document.addEventListener('keyup', escapeListener);

    setModalWidths();
};

export function initModals() {
    const $modalToggleBtns = document.querySelectorAll('.modal-open, .modal-close');
    const $modalResize = document.querySelectorAll('.modal, .modal-resize');

    if ($modalToggleBtns.length) {
        $modalToggleBtns.forEach($modalToggleBtn => {
            const $modal = $modalToggleBtn.dataset?.modalTarget ? document.getElementById($modalToggleBtn.dataset.modalTarget) : false;

            if (!$modal) return;

            $modalToggleBtn.addEventListener('click', e => {
                e.preventDefault();
                
                if ($modal.classList.contains('modal--active') && $modalToggleBtn.classList.contains('modal-close')) {
                    closeModal($modal);
                } else if (!$modal.classList.contains('modal--active') && $modalToggleBtn.classList.contains('modal-open')) {
                    openModal($modal);
                }
            });
        });
    }

    if ($modalResize.length) {
        $modalResize.forEach($modal => {
            if ($modal.id && window.location.hash && '#' + $modal.id === window.location.hash) {
                openModal($modal);
            }
        });

        window.addEventListener('resize', setModalWidths);
    }

    setModalWidths();
}
