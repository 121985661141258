(() => {
    const $subMenuDropdownWraps = document.querySelectorAll('.site-menu-bar__menu > .menu-item-has-children');
    const $subMenuWraps = document.querySelectorAll('.site-menu-bar .menu-item-has-children .menu-item-has-children');
    const $searchBtns = document.querySelectorAll('.site-header-search-btn');
    const $siteSearch = document.querySelector('.site-search');
    const $siteSearchInput = document.querySelector('.site-search__input');
    const $subMenuDropdowns = document.querySelectorAll('.site-menu-bar .sub-menu-dropdown');
    const $subMenuDropdownLists = document.querySelectorAll('.sub-menu-dropdown__list, .sub-menu__list');

    let getSiblings = function (e) {
        // for collecting siblings
        let siblings = []; 
        // if no parent, return no sibling
        if(!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling  = e.parentNode.firstChild;
        
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };

    const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) return;

        const $siblings = getSiblings($subMenuWrap);

        if ($siblings) {
            $siblings.forEach($sibling => {
                if ($sibling.querySelector(`#${$subMenuWrap.id}`)) return;

                disableSubMenu($sibling, $subMenuToggle);
            });
        }

        $subMenuWrap.classList.add('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'true');
    };

    const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        $subMenuWrap.classList.remove('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'false');
    };

    const initSubMenuDropdowns = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                subMenuDropdownHeights();
        
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });
        });
    };

    const initSubMenus = () => {
        $subMenuWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                enableSubMenu($subMenuWrap, $subMenuToggle);
            });
        });
    };

    const initCloseButtons = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('.menu-item__close');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                subMenuDropdownHeights();
        
                disableSubMenu($subMenuWrap, $subMenuToggle);
            });
        });
    };

    const initSiteSearch = () => {
        if (!$searchBtns || !$siteSearch) return;

        $searchBtns.forEach($searchBtn => {
            $searchBtn.addEventListener('click', () => {
                if ($siteSearch.classList.contains('site-search--animating')) return;

                $siteSearch.classList.add('site-search--animating');

                $siteSearch.style.height = `${$siteSearch.scrollHeight}px`;

                if ($siteSearch.classList.contains('site-search--show')) {
                    window.requestAnimationFrame(() => {
                        $siteSearch.classList.remove('site-search--show');
                        $siteSearch.style.height = '';

                        $searchBtns.forEach($searchBtn => {
                            $searchBtn.setAttribute('aria-expanded', 'false');
                        });
                    });
                } else {
                    $siteSearch.classList.add('site-search--show');

                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });

                    setTimeout(() => {
                        $siteSearch.style.height = '';

                        $siteSearchInput.select();

                        $searchBtns.forEach($searchBtn => {
                            $searchBtn.setAttribute('aria-expanded', 'true');
                        });
                    }, 250);
                }

                setTimeout(() => {
                    $siteSearch.classList.remove('site-search--animating');
                }, 250);
            });
        });
    };

    const subMenuDropdownHeights = () => {
        if (!$subMenuDropdowns) return;

        $subMenuDropdowns.forEach($subMenuDropdown => {
            const $subMenuDropdownList = $subMenuDropdown.querySelector('.sub-menu-dropdown__list');
            const $subMenus = $subMenuDropdown.querySelectorAll('.sub-menu');

            if (!$subMenus) return;

            $subMenuDropdownList.style.height = '';

            let height = 0;

            $subMenus.forEach($subMenu => {
                const thisHeight = $subMenu.offsetHeight;

                if (thisHeight > height) {
                    height = thisHeight;
                }
            });

            if ($subMenuDropdownList && height) {
                $subMenuDropdownList.style.height = `${height}px`;
            }
        });
    };

    const enableFirstSubMenus = () => {
        if (!$subMenuDropdownLists) return;

        $subMenuDropdownLists.forEach($subMenuDropdownList => {
            const $subMenuWrap = $subMenuDropdownList.querySelector('.menu-item-has-children');

            if (!$subMenuWrap) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            enableSubMenu($subMenuWrap, $subMenuToggle);
        });
    }

    window.addEventListener('resize', () => {
        subMenuDropdownHeights();
    });

    initSubMenuDropdowns();
    initSubMenus();
    initCloseButtons();
    initSiteSearch();
    subMenuDropdownHeights();
    enableFirstSubMenus();
})();
