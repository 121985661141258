(() => {
    let winWidth = null;

    const setScreenHeights = () => {
        const $siteHeader = document.querySelector('.site-header');
        const $fullHeightEls = document.querySelectorAll('.full-height');
        const $fixedHeight = document.querySelectorAll('.fixed-height');
        const $maxHeightEls = document.querySelectorAll('.max-height');
        const $maxFullHeightEls = document.querySelectorAll('.max-full-height');

        if ($fullHeightEls.length) {
            const height = window.innerHeight - ($siteHeader ? $siteHeader.offsetHeight : 0);

            $fullHeightEls.forEach($el => {
                $el.style.minHeight = `${height}px`;
            });
        }

        if ($fixedHeight.length) {
            $fixedHeight.forEach($el => {
                
                $el.style.height = '';
                
                setTimeout(() => {
                    $el.style.height = `${$el.offsetHeight}px`;
                }, 10);
            });
        }

        if ($maxHeightEls.length) {
            $maxHeightEls.forEach($el => {
                const viewportFraction = $el.dataset.viewportFraction ? parseFloat($el.dataset.viewportFraction) : 1;
                
                $el.style.maxHeight = `${window.innerHeight * viewportFraction}px`;
            });
        }

        if ($maxFullHeightEls.length) {
            $maxFullHeightEls.forEach($el => {
                $el.style.maxHeight = `${window.innerHeight}px`;
            });
        }
    }

    window.addEventListener('resize', () => {
        if (winWidth !== window.innerWidth) {
            setScreenHeights();
        }

        winWidth = window.innerWidth;
    });

    setScreenHeights();
})();
