(() => {
    const $siteMenu = document.querySelector('.site-menu-modal');

    if (!$siteMenu) return;
    
    const initSiteMenuNav = () => {
        const $siteMenuInner = $siteMenu.querySelector('.site-menu-modal__inner');
        const $parentSubMenuWraps = $siteMenu.querySelectorAll('.site-menu-modal__menu > .menu-item-has-children, .site-menu-modal__inner > .menu-item-has-children, .site-menu-modal__scroll > .menu-item-has-children');
        const $childSubMenuWraps = $siteMenu.querySelectorAll('.menu-item-has-children .menu-item-has-children');

        const enableChildSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu-dropdown__inner');

            window.requestAnimationFrame(() => {
                $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

                $subMenuWrap.classList.add('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'true');
                $subMenuToggle.setAttribute('aria-expanded', 'true');

                setTimeout(() => {
                    $subMenu.style.height = '';
                }, 250);
            });
        };
    
        const disableChildSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu-dropdown__inner');

            $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

            window.requestAnimationFrame(() => {
                $subMenu.style.height = '0';
                
                $subMenuWrap.classList.remove('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'false');
                $subMenuToggle.setAttribute('aria-expanded', 'false');
            });

            const $childSubMenus = $subMenuWrap.querySelectorAll('.menu-item-has-children');

            if ($childSubMenus) {
                $childSubMenus.forEach($childSubMenu => {
                    const $subMenuToggle = $childSubMenu.querySelector('a');
    
                    disableChildSubMenu($childSubMenu, $subMenuToggle);
                });
            }
        };
        
        const enableParentSubMenu = ($subMenuWrap, $subMenuToggle) => {
            $subMenuWrap.classList.add('menu-item--active');
        
            $subMenuToggle.setAttribute('aria-expanded', 'true');
            $subMenuToggle.setAttribute('aria-expanded', 'true');
        };
    
        const disableParentSubMenu = ($subMenuWrap, $subMenuToggle) => {
            $subMenuWrap.classList.remove('menu-item--active');
        
            $subMenuToggle.setAttribute('aria-expanded', 'false');
            $subMenuToggle.setAttribute('aria-expanded', 'false');

            const $childSubMenus = $subMenuWrap.querySelectorAll('.menu-item-has-children');

            if ($childSubMenus) {
                $childSubMenus.forEach($childSubMenu => {
                    const $subMenuToggle = $childSubMenu.querySelector('a');
    
                    disableChildSubMenu($childSubMenu, $subMenuToggle);
                });
            }
        };

        const initParentSubMenuWraps = () => {
            if (!$parentSubMenuWraps) return;
        
            $parentSubMenuWraps.forEach($parentSubMenuWrap => {
                const $subMenuToggle = $parentSubMenuWrap.querySelector('a, button');
                const $subMenuBackBtn = $parentSubMenuWrap.querySelector('.sub-menu-dropdown__back-btn');

                if ($subMenuToggle) {
                    $subMenuToggle.addEventListener('click', e => {
                        e.preventDefault();
                        
                        if ($parentSubMenuWrap.classList.contains('menu-item--active')) {
                            disableParentSubMenu($parentSubMenuWrap, $subMenuToggle);
                        } else {
                            enableParentSubMenu($parentSubMenuWrap, $subMenuToggle);
                        }
                    });
                }

                if ($subMenuBackBtn) {
                    $subMenuBackBtn.addEventListener('click', () => {
                        disableParentSubMenu($parentSubMenuWrap, $subMenuToggle);
                    });
                }
            });
        };
    
        const initChildSubMenuWraps = () => {
            if (!$childSubMenuWraps) return;
        
            $childSubMenuWraps.forEach($childSubMenuWrap => {
                const $subMenuToggle = $childSubMenuWrap.querySelector('a');

                if ($subMenuToggle) {
                    $subMenuToggle.addEventListener('click', e => {
                        e.preventDefault();
                        
                        if ($childSubMenuWrap.classList.contains('menu-item--active')) {
                            disableChildSubMenu($childSubMenuWrap, $subMenuToggle);
                        } else {
                            enableChildSubMenu($childSubMenuWrap, $subMenuToggle);
                        }
                    });
                }
            });
        };

        initParentSubMenuWraps();
        initChildSubMenuWraps();
    };

    initSiteMenuNav();
})();
