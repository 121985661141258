// Fonts
import '../fonts/Altform-Regular.woff2';
import '../fonts/Altform-RegularItalic.woff2';
import '../fonts/Altform-SemiBold.woff2';
import '../fonts/Altform-SemiBoldItalic.woff2';

// Images
import '../img/armourcoat-a-pattern-white.svg';
import '../img/armourcoat-a-pattern-dark.svg';
import '../img/email-logo.png';
import '../img/email-pattern.png';
import '../img/email-shim.gif';

import '../sass/main.scss';

import './common/css-variables.js';
import './common/custom-select.js';
import './common/focus.js';
import './common/screen-width.js';
import './common/screen-height.js';
import './common/forms.js';
import './common/anchors.js';

import './layouts/cookies-notice.js';
import './layouts/site-header.js';
import './layouts/site-menu-modal.js';
import './layouts/site-menu-bar.js';
import './layouts/site-nav.js';
import './layouts/site-footer.js';

import './common/on-screen.js';
import { initModals } from './common/modals';

(() => {
    initModals();
})();
