(() => {
    const $selectMultiples = document.querySelectorAll('.select-multiple');

    if (!$selectMultiples) return;

    $selectMultiples.forEach($selectMultiple => {
        const $select = $selectMultiple.querySelector('.select-multiple__select');
        const $selectMultipleLabel = $selectMultiple.querySelector('.select-multiple__label');
        const $selectMultipleResults = $selectMultiple.querySelector('.select-multiple__results');
        const $selectMultipleResultsList = $selectMultiple.querySelector('.select-multiple__results-list');
        const $selectMultipleResultsBg = $selectMultiple.querySelector('.select-multiple__results-bg');

        const setSelectMultiplePosition = () => {
            if (!$selectMultipleResults) return;

            $selectMultipleResults.classList.remove('select-multiple__results--right');

            if ($selectMultipleResults.getBoundingClientRect().x + $selectMultipleResults.clientWidth > document.body.clientWidth) {
                $selectMultipleResults.classList.add('select-multiple__results--right');
            }
        };

        const initSelectMultiple = () => {
            if (!$select) return;

            const $options = $select.querySelectorAll('option');

            if (!$options.length) return;

            let selectedOptions = Array.from($options).filter($option => $option.selected);
            selectedOptions = selectedOptions ? selectedOptions.map(selectedOption => selectedOption.value) : null;

            $options.forEach($option => {
                const { resultsCount, colourHex, border } = $option.dataset;

                $selectMultipleResultsList.insertAdjacentHTML(
                    'beforeend',
                    `<button class="select-multiple__results-button 
                        ${selectedOptions.includes($option.value) ? 'select-multiple__results-button--active' : ''} 
                        sub-heading font-size-12" 
                        type="button" 
                        data-value="${$option.value}">

                        <div class="select-multiple__results-button-text">
                            ${$option.text}
                            ${resultsCount ? `<span class="color--slate-grey">(${resultsCount})</span>` : ''}
                        </div>

                        <div class="select-multiple__results-button-check
                            ${colourHex && !['#fff', '#ffffff'].includes(colourHex.toLocaleLowerCase()) ? `select-multiple__results-button-check--border-transparent` : ''}
                            ${colourHex && ['#fff', '#ffffff'].includes(colourHex.toLocaleLowerCase()) ? `select-multiple__results-button-check--border-grey` : ''}
                            ${border ? `select-multiple__results-button-check--border-${border}` : ''}"
                            style="${colourHex ? `background-color: ${colourHex}` : ''}">

                            <svg version="1.1" 
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32">

                                <path d="m10.7 16.1 3.2 3.1 6.8-7"/>
                            </svg>
                        </div>
                    </button>`
                );
            });

            const $selectMultipleResultsButtons = $selectMultipleResultsList.querySelectorAll('button');

            $selectMultipleLabel.addEventListener('click', () => {
                const $activeSelects = document.querySelectorAll('.select-multiple--active');

                const isActive = $selectMultiple.classList.contains('select-multiple--active');

                if ($activeSelects) {
                    $activeSelects.forEach($activeSelect => {
                        $activeSelect.classList.remove('select-multiple--active');
                    });
                }

                if (!isActive) {
                    $selectMultiple.classList.add('select-multiple--active');
                }
            });

            if ($selectMultipleResultsButtons) {
                $selectMultipleResultsButtons.forEach($selectMultipleResultsButton => {
                    $selectMultipleResultsButton.addEventListener('click', () => {
                        const { value } = $selectMultipleResultsButton.dataset;
                        
                        $selectedOption = $select.querySelector(`option[value="${value}"]`);

                        if (!$selectedOption) return;

                        const isSelected = $selectedOption.selected;

                        $selectedOption.selected = !isSelected;

                        $select.dispatchEvent(new Event('change'));
                    });
                });

                $select.addEventListener('change', () => {
                    $selectMultipleResultsButtons.forEach($selectMultipleResultsButton => {
                        const { value } = $selectMultipleResultsButton.dataset;
                        
                        $selectedOption = $select.querySelector(`option[value="${value}"]`);

                        if (!$selectedOption) return;

                        const isSelected = $selectedOption.selected;

                        if (isSelected) {
                            $selectMultipleResultsButton.classList.add('select-multiple__results-button--active');
                        } else {
                            $selectMultipleResultsButton.classList.remove('select-multiple__results-button--active');
                        }
                    });
                });
            }

            if ($selectMultipleResultsBg) {
                $selectMultipleResultsBg.addEventListener('click', () => {
                    $selectMultiple.classList.remove('select-multiple--active');
                });
            }

            setSelectMultiplePosition();
        }

        window.addEventListener('resize', setSelectMultiplePosition);

        initSelectMultiple();
    });
})();
