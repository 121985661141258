import Details from "../classes/Details";

(() => {
    const $menuDropdowns = document.querySelectorAll('.site-footer-menu__dropdown');

    const initMenuDropdowns = () => {
        if (!$menuDropdowns) return;

        $menuDropdowns.forEach($menuDropdown => {
            new Details($menuDropdown);
        });
    };

    initMenuDropdowns();
})();
