(() => {
    const $siteHeader = document.querySelector('.site-header');
    const $anchors = document.querySelectorAll('a');

    if (!$anchors) return;

    $anchors.forEach($anchor => {
        if (!$anchor?.href) return;
        
        const anchorURL = new URL($anchor.href);

        if (!anchorURL?.hash) return;

        const $anchorEl = document.querySelector(anchorURL?.hash);

        if (!$anchorEl) return;

        $anchor.addEventListener('click', e => {
            e.preventDefault();

            window.scrollTo({
                top: $anchorEl.getBoundingClientRect().top + window.scrollY - ($siteHeader ? $siteHeader.clientHeight : 0),
                behavior: 'smooth'
            });
        });
    });
})();
